import funcVars from '../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';
import areasResource from '../../services/AreasResource';
import { state } from '@/vuex/store';
import store from '@/vuex/store';
import Area from '@/models/Area';
export const actions: ActionTree<AppState, RootState> = {
  async getAreas({ commit }) {
    return await areasResource
      .getAreas(
        state.fromDate,
        state.toDate,
        state.searchTerm,
        state.areaCampingTypes,
        state.propertyValues
      )
      .then((response: Area[]) => {
        commit(funcVars.updateAreas, response);
        return response;
      });
  },
  async getAreasForUser({ commit }) {
    return await areasResource.getAreasForUser().then((response: Area[]) => {
      commit(funcVars.updateAreasForUser, response);
      return response;
    });
  },
  async setArea({ commit }, payload) {
    return await areasResource
      .getArea(
        payload.urlFriendlyName,
        payload.campaignCode,
        state.fromDate,
        state.toDate,
        payload.productReservations,
        payload.areaId
      )
      .then((response: Area) => {
        commit(funcVars.updateArea, response);
        return response;
      });
  },
  async removeArea({ commit }, areaId: string) {
    return await areasResource.removeArea(areaId).then(() => {
      // Trigger get reservations
      store.dispatch('getAreasForUser');
    });
  },
  async updateActiveArea({ commit }, activeArea) {
    commit(funcVars.updateActiveArea, activeArea);
  },
};
