<template>
  <div v-if="isOpen">
    <div class="u-backdrop"></div>
    <ion-toast mode="ios" :is-open="isOpen" :message="$t(response?.message)"
      :buttons="[{ text: $t('ui.buttons.ok'), color: 'white' }]" @didDismiss="close()">
      <ion-button @click="close()">ok</ion-button>
    </ion-toast>
  </div>
</template>

<script setup lang="ts">
import NotificationService from '../../services/NotificationService';
import { ref } from 'vue';
import { IonToast, IonButton } from '@ionic/vue';

const props = defineProps({ response: Object as any });
const isOpen = ref(true);
const close = (): void => {
  NotificationService.destroy(props.response.id);
  isOpen.value = false;
  if (typeof props.response.callback === 'function') {
    props.response.callback();
  }
}
</script>
