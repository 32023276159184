<template>
  <ion-app>
    <ion-menu side="end" menuId="mainMenu" contentId="main" :maxEdgeStart="0">
      <ion-header>
        <ion-toolbar translucent>
          <ion-title>{{ $t('ui.labels.menu') }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item @click.prevent="
            $router.push(
              '/' +
              $i18n.locale +
              '/' +
              $t('routes.about.route') +
              '/' +
              $t('routes.about_rv_owner.route')
            )
            ">
            <ion-icon :icon="informationOutline" slot="start"></ion-icon>
            <ion-label>{{ $t('ui.headings.about') }}</ion-label>
          </ion-item>
          <ion-item @click.prevent="
            $router.push(
              '/' + $i18n.locale + '/' + $t('routes.terms.route')
            )
            ">
            <ion-icon :icon="readerOutline" slot="start"></ion-icon>
            <ion-label>{{ $t('terms.heading') }}</ion-label>
          </ion-item>
          <ion-item @click.prevent="
            $router.push(
              '/' + $i18n.locale + '/' + $t('routes.policy.route')
            )
            ">
            <ion-icon :icon="readerOutline" slot="start"></ion-icon>
            <ion-label>{{ $t('policy.heading') }}</ion-label>
          </ion-item>
          <ion-item @click.prevent="
            $router.push(
              '/' + $i18n.locale + '/settings/myplaces/create-area'
            )
            ">
            <ion-icon slot="start" src="/images/icons/eremit-icon-black.svg"></ion-icon>
            <ion-label>{{ $t('ui.buttons.join_eremit') }}</ion-label>
          </ion-item>

          <ion-item lines="none">
            <ion-icon src="/images/icons/sv.svg" slot="end" @click.prevent="utils.changeLanguage('sv')"></ion-icon>

            <ion-icon src="/images/icons/en.svg" slot="end" @click.prevent="utils.changeLanguage('en')"></ion-icon>

            <ion-icon src="/images/icons/de.svg" slot="end" @click.prevent="utils.changeLanguage('de')"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-tabs>
      <ion-router-outlet id="main" />
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" @click="store.dispatch('getReservations')" :href="'/' +
          $i18n.locale +
          '/' +
          $t('routes.reservations.route')
          ">
          <ion-icon :icon="calendarNumberOutline" />
          <ion-label>{{ $t('ui.headings.reservations') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" :href="'/' + $i18n.locale + '/' + $t('routes.areas.route')" class="tab-button-large">
          <ion-fab-button mode="md">
            <ion-icon src="/images/logo-white.svg" size="large" class="white-icon" />
            <ion-icon src="/images/icons/favicon.svg" size="large" class="color-icon" />
          </ion-fab-button>
          <ion-label>{{ $t('ui.headings.areas') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab3" :href="'/' +
          $i18n.locale +
          '/' +
          $t('routes.settings.route')
          ">
          <ion-icon :icon="settingsOutline" />
          <ion-label>{{ $t('ui.headings.settings') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <loader-component />
    <NotificationListBlock />
    <div id="lightbox"></div>
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonTabBar,
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonItem,
  IonIcon,
  IonList,
  IonLabel,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  menuController,
  IonTabButton,
  IonTabs,
  IonFabButton,
} from '@ionic/vue';
import NotificationListBlock from './components/common/NotificationListBlock.vue';
import utils from '@/services/Utils';
import LoaderComponent from '@/components/common/LoaderComponent.vue';
import i18n from '@/i18n/i18n';
import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/vuex/store';
import {
  calendarNumberOutline,
  settingsOutline,
  informationOutline,
  readerOutline,
} from 'ionicons/icons';

const route = useRoute();

window.menuController = menuController;

watch(() => route.path, () => {

  const currentRoute = route.name?.toString();

  if (currentRoute) {
    const metaDescription = i18n.global.te(
      'routes.' + currentRoute + '.meta_description'
    )
      ? i18n.global.t('routes.' + currentRoute + '.meta_description')
        .toString()
      : '';

    const title = i18n.global.te('routes.' + currentRoute + '.meta_title')
      ? i18n.global.t('routes.' + currentRoute + '.meta_title').toString()
      : '';

    // Set metadata
    const metaDescriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const titleElement: HTMLElement = document.querySelector('title') as HTMLElement;

    if (metaDescriptionElement && metaDescription.length > 1) {
      metaDescriptionElement?.setAttribute('content', metaDescription);
    }

    if (titleElement && title.length > 1) {
      titleElement.innerText = title;
    } else {
      titleElement.innerText = 'Eremit';
    }
  }
});

onMounted(() => {
  if (store.getters['currentUser']) {
    store.dispatch('getReservations');
  }
});

</script>
