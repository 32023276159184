import Axios from 'axios';
import BaseResource from './BaseResource';

export default new (class CommentResource extends BaseResource {
  public urlPath = 'comments/';

  constructor() {
    super();
  }

  public createComment(comment: any) {
    return Axios.post(this.getBaseUrl() + this.urlPath, comment)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public removeComment(commentId: any) {
    return Axios.delete(this.getBaseUrl() + this.urlPath + commentId)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public getComments(areaId: any): Promise<any> {
    return Axios.get(this.getBaseUrl() + this.urlPath, {
      params: {
        areaId,
      },
    })
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
})();
