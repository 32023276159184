import { StorageTypes } from '../enums/StorageTypes';

class StorageResource {
  private defaultPrefix: string;

  constructor(defaultPrefix: string) {
    this.defaultPrefix = defaultPrefix;
  }

  public getItem(storageType: number, key: string, prefix?: string): any {
    const finalPrefix = prefix ? prefix : this.defaultPrefix;
    const keyToGet = finalPrefix + key;
    const item =
      storageType === StorageTypes.SESSION
        ? sessionStorage.getItem(keyToGet)
        : localStorage.getItem(keyToGet);
    try {
      if (item != null) {
        const json = JSON.parse(item);
        return json;
      }
    } catch {
      return item || '';
    }
  }

  public setItem(
    storageType: number,
    key: string,
    value: any,
    prefix?: string
  ): void {
    const finalPrefix = prefix ? prefix : this.defaultPrefix;
    const keyToSet = finalPrefix + key;
    if (storageType === StorageTypes.SESSION) {
      sessionStorage.setItem(keyToSet, value);
    } else {
      localStorage.setItem(keyToSet, value);
    }
  }

  public removeItem(storageType: number, key: string, prefix?: string): void {
    const finalPrefix = prefix ? prefix : this.defaultPrefix;
    const keyToRemove = finalPrefix + key;
    sessionStorage.removeItem(keyToRemove);
    if (storageType === StorageTypes.SESSION) {
      sessionStorage.removeItem(keyToRemove);
    } else {
      localStorage.removeItem(keyToRemove);
    }
  }

  public clear(storageType: number): void {
    if (storageType === StorageTypes.SESSION) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  }
}

const storageService = new StorageResource('_eremit');

export default storageService;
