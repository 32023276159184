import Area from '@/models/Area';
import { GetterTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';

export const getters: GetterTree<AppState, RootState> = {
  areas(state: AppState): any[] {
    return state.areas || [];
  },
  areasForUser(state: AppState): any[] {
    return state.areasForUser || [];
  },
  area(state: AppState): Area | null {
    return state.area || null;
  },
  activeArea(state: AppState): Area | null {
    return state.activeArea || null;
  },
};
