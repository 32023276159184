import Area from '@/models/Area';
import { MutationTree } from 'vuex';
import { AppState } from '../../vuex/types';
import funcVars from '../../vuex/vuexVars';

export const mutations: MutationTree<AppState> = {
  [funcVars.updateAreas](state: AppState, payload: []) {
    state.areas = payload;
  },
  [funcVars.updateAreasForUser](state: AppState, payload: []) {
    state.areasForUser = payload;
  },
  [funcVars.updateArea](state: AppState, payload: Area | null) {
    state.area = payload;
  },
  [funcVars.updateActiveArea](state: AppState, payload: Area | null) {
    state.activeArea = payload;
  },
};
