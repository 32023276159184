<template>
  <ion-loading :is-open="loaderMessage != null && loaderMessage != undefined" :message="loaderMessage">
  </ion-loading>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { IonLoading } from "@ionic/vue";
import store from "@/vuex/store";

const loaderMessage = computed(() => {
  return store.getters["loaderMessage"];
});

</script>
