import { createI18n } from 'vue-i18n';

import sv from './lang/sv.json';
import en from './lang/en.json';
import de from './lang/de.json';

// Get active language from browser
const browserlanguage = navigator.language || navigator.userLanguage;
const activeLang = localStorage.lang
  ? localStorage.lang
  : browserlanguage.indexOf('sv') !== -1
  ? 'sv'
  : browserlanguage.indexOf('de') !== -1
  ? 'de'
  : 'en';

const i18n = createI18n({
  locale: activeLang,
  fallbackLocale: 'sv',
  warnHtmlInMessage: 'off',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  globalInjection: true,
  messages: {
    sv,
    en,
    de,
  },
});

export default i18n;
