import { createRouter, createWebHistory } from '@ionic/vue-router';
import i18n from '@/i18n/i18n';
export default new (class RouterResource {
  public browserlanguage = navigator.language || navigator.userLanguage;
  public activeBrowserLanguage =
    this.browserlanguage.indexOf('sv') !== -1
      ? 'sv'
      : this.browserlanguage.indexOf('de')
      ? 'de'
      : 'en';
  public routes: any[] | null = null;
  public router: any = null;

  public getRoutes() {
    if (!this.routes) {
      this.routes = [
        {
          path: '',
          redirect:
            '/' +
            i18n.global.locale +
            '/' +
            i18n.global.t('routes.areas.route'),
        },
        // {
        //   path: '/:lang/choose-type',
        //   component: () => import('@/views/SelectAreaTypePage.vue'),
        //   name: 'start',
        // },

        {
          path: '/:lang/' + i18n.global.t('routes.areas.route'),
          name: i18n.global.t('routes.areas.route'),
          component: () => import('@/views/Areas/AreasDetailsPage.vue'),
        },
        {
          path:
            '/:lang/' + i18n.global.t('routes.areas.route') + '/:areaUrlName',
          name: i18n.global.t('routes.area.route'),
          component: () => import('@/views/Areas/AreaDetailsPage.vue'),
        },

        {
          path: '/:lang/' + i18n.global.t('routes.reservations.route'),
          name: i18n.global.t('routes.reservations.route'),
          component: () => import('@/views/ReservationsPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.reservations.route') +
            '/' +
            i18n.global.t('routes.reservation_details.route'),
          component: () =>
            import('@/views/Reservations/ReservationDetailsPage.vue'),
          name: 'reservation',
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.reservations.route') +
            '/:reservationId',
          component: () =>
            import('@/views/Reservations/ReservationDetailsPage.vue'),
          name: 'reservation',
          children: [
            {
              path: ':pageLocation',
              component: () =>
                import('@/views/Reservations/ReservationDetailsPage.vue'),
              name: 'reservationPageLocation',
            },
          ],
        },
        {
          path: '/:lang/' + i18n.global.t('routes.settings.route'),
          name: i18n.global.t('routes.settings.route'),
          component: () => import('@/views/Settings/SettingsDetailsPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.settings.route') +
            '/' +
            i18n.global.t('routes.profile.route'),
          name: i18n.global.t('routes.profile.name'),
          component: () => import('@/views/Settings/ProfileDetailsPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.settings.route') +
            '/' +
            i18n.global.t('routes.myplaces.route'),
          name: i18n.global.t('routes.myplaces.name'),
          component: () => import('@/views/Settings/MyPlacesPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.settings.route') +
            '/' +
            i18n.global.t('routes.myplaces.route') +
            '/' +
            i18n.global.t('routes.create_area.route'),
          name: i18n.global.t('routes.create_area.route') + 'empty',
          component: () => import('@/views/Settings/CreateOrEditAreaPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.settings.route') +
            '/' +
            i18n.global.t('routes.myplaces.route') +
            '/' +
            i18n.global.t('routes.create_area.route') +
            '/:act',
          name: i18n.global.t('routes.create_area.route'),
          component: () => import('@/views/Settings/CreateOrEditAreaPage.vue'),
        },
        {
          path:
            '/:lang/' +
            i18n.global.t('routes.settings.route') +
            '/' +
            i18n.global.t('routes.myplaces.route') +
            '/' +
            i18n.global.t('routes.edit_area.route') +
            '/:id',
          name: i18n.global.t('routes.edit_area.route'),
          component: () => import('@/views/Settings/CreateOrEditAreaPage.vue'),
        },
        {
          path: '/:lang/' + i18n.global.t('routes.terms.route'),
          name: i18n.global.t('routes.terms.route'),
          component: () => import('@/views/TermsPage.vue'),
        },
        {
          path: '/:lang/' + i18n.global.t('routes.terms_land_owner.route'),
          name: i18n.global.t('routes.terms_land_owner.route'),
          component: () => import('@/views/TermsLandOwnerPage.vue'),
        },
        {
          path: '/:lang/' + i18n.global.t('routes.policy.route'),
          name: i18n.global.t('routes.policy.route'),
          component: () => import('@/views/PolicyPage.vue'),
        },
        {
          path: '/:lang/' + i18n.global.t('routes.about.route'),
          name: i18n.global.t('routes.about.route'),
          component: () => import('@/views/AboutPage.vue'),

          children: [
            {
              path: i18n.global.t('routes.about_land_owner.route'),
              component: () => import('@/views/About/AboutLandownerPage.vue'),
              name: i18n.global.t('routes.about_land_owner.route'),
            },
            {
              path: i18n.global.t('routes.about_rv_owner.route'),
              component: () => import('@/views/About/AboutRVownerPage.vue'),
              name: i18n.global.t('routes.about_rv_owner.route'),
            },
          ],
        },

        {
          path: '/:lang/authentication/:action',
          component: () => import('@/views/AuthorizationPage.vue'),
        },
        {
          // path: "*",
          path: '/:lang/:catchAll(.*)',
          name: 'NotFound',
          component: () => import('@/views/NotFoundPage.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        // {
        //   path: '/:lang/:pathMatch(.*)*',
        //   component: () => import('@/views/NotFoundPage.vue'),
        //   name: '404-route',
        // },
        // {
        //   path: "/:pathMatch(.*)*",
        //   component: () => import("@/views/NotFoundPage.vue"),
        // },
      ];
    }
    return this.routes;
  }

  public getRouter() {
    if (!this.router) {
      this.router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: this.getRoutes(),
        linkExactActiveClass: 'active',
        linkActiveClass: 'open',
      });

      this.router.beforeEach((to: any, from: any, next: any) => {
        // Evaluate whether or not the URL contains a language
        let hasLang =
          (to.params && to.params.lang) || (from.params && from.params.lang);

        // Get the save language if it exists
        let lang = this.activeBrowserLanguage;

        // Overwrite the language with the route if there is one
        if (to.params.lang) {
          lang = to.params.lang.toLowerCase();
        } else if (from.params.lang) {
          lang = from.params.lang.toLowerCase();
        }

        // Make sure the language is valid
        if (['en', 'sv', 'de'].indexOf(lang) == -1) {
          lang = i18n.global.locale ?? 'en';
          hasLang = false;
        }

        // Set the website language based on the URL
        if (i18n.global.locale !== lang) {
          i18n.global.locale = lang;
        }

        localStorage.lang = lang;

        // Redirect to a url with the language
        if (!hasLang) {
          return next(`/${lang}${to.fullPath}`);
        }

        // Redirect to areas it only language in url
        if (hasLang && to.fullPath?.length < 6) {
          return next(`/${lang}/areas`);
        }

        return next();
      });
    }

    return this.router;
  }
})();
