import { GetterTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';

export const getters: GetterTree<AppState, RootState> = {
  activeTab(state: AppState): any {
    return state.activeTab;
  },

  loaderMessage(state: AppState): any {
    return state.loaderMessage;
  },

  texts(state: AppState): any {
    return state.texts;
  },

  hasShownOnboarding(state: AppState): any {
    return state.hasShownOnboarding;
  },

  notifications(state: AppState): any {
    return state.notifications;
  },
};
