import funcVars from '../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';

let loaderTimeout: any;

export const actions: ActionTree<AppState, RootState> = {
  async setActiveTab({ commit }, payload: any) {
    commit(funcVars.updateActiveTab, payload.index);
  },

  async setLoaderMessage({ commit }, loaderMessage: any) {
    if (loaderMessage === null) {
      if (loaderTimeout) {
        clearTimeout(loaderTimeout);
      }

      loaderTimeout = setTimeout(() => {
        commit(funcVars.updateLoaderMessage, loaderMessage);
      }, 800);
    } else {
      if (loaderTimeout) {
        clearTimeout(loaderTimeout);
      }

      commit(funcVars.updateLoaderMessage, loaderMessage);
    }
  },

  async setTexts({ commit }, texts: any) {
    commit(funcVars.updateTexts, texts);
  },
  async updateHasShownOnboarding({ commit }, payload: boolean) {
    if (payload) {
      commit(funcVars.updateHasShownOnboarding, payload);
    }
  },
};
