export const StorageKeys = Object.freeze({
  B2C_ACCESS_TOKEN: 'b2caccesstoken',
  B2C_ID_TOKEN: 'b2cidtoken',
  B2C_CLIENT_ID: 'b2cclientid',
  B2C_NATIVE_CLIENT_ID: 'b2cnativeclientid',
  B2C_AUTHORITY: 'b2cauthority',
  B2C_PASSWORDRESETAUTHORITY: 'b2cpasswordresetauthority',
  B2C_CLIENT_URI: 'b2cclienturi',
  B2C_SCOPE: 'b2cscope',
  B2C_EDIT_PROFILE_URL: 'b2cseditprofileurl',
  API: 'api',
  SHOWN_ON_BOARDING_SCREEN: 'sobs',
  FROM_DATE: 'fd',
  TO_DATE: 'td',
  PROPERTY_VALUES: 'apv',
  AREA_CAMPING_TYPES: 'act',
  HAS_ACCEPTED_COOKIES: 'hac',
  TIME_OF_DAY: 'tod',
});
