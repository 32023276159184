import Axios from 'axios';
import BaseResource from './BaseResource';
import Area from '@/models/Area';
import { AreaCampingType } from '@/enums/AreaCampingType';

export default new (class AreasResource extends BaseResource {
  public urlPath = 'areas';

  constructor() {
    super();
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getArea(
    urlFriendlyName: any,
    campaignCode: string | undefined,
    fromDate: Date,
    toDate: Date,
    productReservations: Array<any>,
    areaId: string,
    reservationIdToSkip: string | undefined = undefined
  ): Promise<Area> {
    return Axios.post(this.getBaseUrl() + this.urlPath + '/getarea', {
      urlFriendlyName,
      fromDate,
      toDate,
      campaignCode,
      productReservations,
      areaId,
      reservationIdToSkip,
    })
      .then((response) => response.data as Promise<Area>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getAutoCompleteItems(searchTerm: string): Promise<any> {
    return Axios.get(
      this.getBaseUrl() +
        this.urlPath +
        '/getAutoCompleteItems/' +
        searchTerm +
        '?hideLoader',
      {
        params: {},
      }
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getAreaForEdit(areaId: any): Promise<any> {
    return Axios.get(
      this.getBaseUrl() + this.urlPath + '/getareaforedit/' + areaId,
      {
        params: {},
      }
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * This function returns an promise, that can be resolved as a array with areas.
   */
  public getAreas(
    fromDate: Date,
    toDate: Date,
    searchTerm: string,
    areaCampingTypes: AreaCampingType[],
    propertyValues: any[]
  ): Promise<any> {
    return Axios.post(this.getBaseUrl() + this.urlPath, {
      fromDate,
      toDate,
      searchTerm,
      areaCampingTypes,
      areaPropertyValues: propertyValues,
      propertyTypeIds: [], // TODO: Remove after release
      appVersion: process.env.VUE_APP_VERSION,
    })
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public getAreasForUser(): Promise<any> {
    return Axios.get(this.getBaseUrl() + this.urlPath + '/getareasforuser/', {
      params: {},
    })
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  /**
   * This function updates the camper area
   * @param area Camper area to update
   * @returns Promise that can be resolved as a camper area.
   */
  public createOrUpdateArea(area: Area): Promise<any> {
    return Axios.post(
      this.getBaseUrl() + this.urlPath + '/createorupdatearea/',
      area
    )
      .then((response) => response.data as Promise<Area>)
      .then((data) => data)
      .catch((error: any) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public removeArea(areaId: string) {
    return Axios.delete(this.getBaseUrl() + this.urlPath + '/' + areaId)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
})();
