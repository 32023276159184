export default class MarkerData {
  public latitude = '';
  public longitude = '';
  public information = '';
  public icon = '';
  public title = '';
  public label: string | null = '';
  public width = 32;
  public height = 32;
  public draggable = false;
  public zIndex = 1;
  public isArea = false;

  public constructor(
    latitude: string,
    longitude: string,
    icon: string,
    information: string,
    title: string,
    label: string | null,
    width: number,
    height: number,
    draggable: boolean,
    zIndex = 1,
    isArea: boolean
  ) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.information = information;
    this.icon = icon;
    this.title = title;
    this.label = label;
    this.width = width;
    this.height = height;
    this.draggable = draggable;
    this.zIndex = zIndex;
    this.isArea = isArea;
  }
}
