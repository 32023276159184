import type { CreateReservationTransactionRequest } from '@/models/interfaces/CreateReservationTransactionRequest';
import Axios from 'axios';
import BaseResource from './BaseResource';
export default new (class ReservationResource extends BaseResource {
  public urlPath = 'reservations/';

  constructor() {
    super();
  }

  public createReservation(reservation: any) {
    return Axios.post(this.getBaseUrl() + this.urlPath, reservation)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public updateReservation(reservation: any) {
    return Axios.post(
      this.getBaseUrl() + this.urlPath + 'updatereservation',
      reservation
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public createReservationTransaction(
    request: CreateReservationTransactionRequest
  ) {
    return Axios.post(
      this.getBaseUrl() + this.urlPath + 'createreservationtransaction',
      request
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public creditReservation(reservationId: any) {
    return Axios.post(
      this.getBaseUrl() + this.urlPath + 'creditreservation/' + reservationId
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public cancelReservation(reservationId: any) {
    return Axios.post(
      this.getBaseUrl() + this.urlPath + 'cancelreservation/' + reservationId
    )
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public removeReservation(reservationId: any) {
    return Axios.delete(this.getBaseUrl() + this.urlPath + reservationId)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public getReservation(reservationId: any): Promise<any> {
    if (reservationId) {
      return Axios.post(this.getBaseUrl() + this.urlPath + reservationId)
        .then((response) => response.data as Promise<any>)
        .then((data) => data)
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    }

    return Promise.resolve(null);
  }

  public getReservations(): Promise<any> {
    return Axios.get(this.getBaseUrl() + this.urlPath)
      .then((response) => response.data as Promise<any>)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
})();
