export default new (class Polyfills {
  public init() {
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s: any) {
        let el: any = this;
        let count = 0;
        do {
          if (el.matches(s)) {
            return el;
          }
          el = el.parentElement || el.parentNode;
          count++;
        } while (el !== null && el.nodeType === 1 && count < 10);
        return null;
      };
    }
  }
})();
