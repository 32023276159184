import { StorageTypes } from '../enums/StorageTypes';
import { StorageKeys } from '../enums/StorageKeys';
import storageService from './StorageResource';

export default class BaseResource {
  public baseUrl = '';

  constructor() {
    const apiUrl = storageService.getItem(StorageTypes.LOCAL, StorageKeys.API);
    this.baseUrl = (apiUrl ? apiUrl.replace(/\/+$/, '') : '') + '/api/';
  }

  public getBaseUrl() {
    return this.baseUrl && this.baseUrl !== '/api/'
      ? this.baseUrl
      : storageService
          .getItem(StorageTypes.LOCAL, StorageKeys.API)
          ?.replace(/\/+$/, '') + '/api/';
  }
}
