import funcVars from '../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';
import store from '@/vuex/store';
import commentResource from '@/services/CommentResource';

export const actions: ActionTree<AppState, RootState> = {
  async getComments({ commit }, areaId: string) {
    return await commentResource
      .getComments(areaId)
      .then((response: Comment[]) => {
        commit(funcVars.updateComments, response);
        return response;
      });
  },
  async createComment({ commit }, payload: any) {
    return await commentResource
      .createComment(payload)
      .then((response: any) => {
        // Trigger get comments
        store.dispatch('getComments', payload.areaId);

        return response;
      });
  },
  async removeComment({ commit }, payload: any) {
    return await commentResource
      .removeComment(payload)
      .then((response: any) => {
        // Trigger get comments
        return response;
      });
  },
};
