import { MutationTree } from 'vuex';
import { AppState } from './../../vuex/types';
import funcVars from './../../vuex/vuexVars';
import { FilterGroup, FilterType } from '../../vuex/types';
import { StorageTypes } from '@/enums/StorageTypes';
import { StorageKeys } from '@/enums/StorageKeys';
import storageService from '@/services/StorageResource';
import { TimeOfDay } from '@/enums/TimeOfDay';
export const mutations: MutationTree<AppState> = {
  [funcVars.updateFromDate](state: AppState, payload: Date) {
    state.fromDate = payload;
    storageService.setItem(StorageTypes.LOCAL, StorageKeys.FROM_DATE, payload);
  },
  [funcVars.updateTimeOfDay](state: AppState, timeOfDay: TimeOfDay) {
    state.timeOfDay = timeOfDay;
    storageService.setItem(
      StorageTypes.LOCAL,
      StorageKeys.TIME_OF_DAY,
      timeOfDay
    );
  },
  [funcVars.updateToDate](state: AppState, payload: Date) {
    state.toDate = payload;
    storageService.setItem(StorageTypes.LOCAL, StorageKeys.TO_DATE, payload);
  },
  [funcVars.updatePropertyValues](state: AppState, payload: any) {
    state.propertyValues = payload;
    storageService.setItem(
      StorageTypes.LOCAL,
      StorageKeys.PROPERTY_VALUES,
      JSON.stringify(payload)
    );
  },
  [funcVars.updateAreaCampingTypes](state: AppState, payload: any) {
    state.areaCampingTypes = payload;
    storageService.setItem(
      StorageTypes.LOCAL,
      StorageKeys.AREA_CAMPING_TYPES,
      JSON.stringify(payload)
    );
  },
  [funcVars.updateSearchTerm](state: AppState, payload: string) {
    state.searchTerm = payload;
  },
  [funcVars.getActiveFilters](state: AppState, payload: FilterType[]) {
    if (!!state.activeFilters) {
      state.activeFilters = payload;
    }
  },
  [funcVars.getActiveFilters](state: AppState, payload: FilterType[]) {
    if (!!state.activeFilters) {
      state.activeFilters = payload;
    }
  },
  [funcVars.fetchFilters](state: AppState, payload: FilterGroup[]) {
    if (!!state.filters) {
      state.filters = payload;
    }
  },
  [funcVars.clearActiveFilters](state: AppState) {
    if (state.activeFilters && state.activeFilters.length > 0) {
      state.activeFilters = [];
    }
  },
  // Setting filters to active that is included in activeFilters
  [funcVars.updateShadowFilters](state: AppState) {
    const activeFilterList = (state.activeFilters as FilterType[]).map(
      (filter) => {
        return filter.id;
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !!state.filters &&
      state.filters.map((aFilterGroup, groupIndex) => {
        // tslint:disable-next-line: no-unused-expression
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !!aFilterGroup.filters &&
          aFilterGroup.filters.map((aFilterItem, itemIndex) => {
            if (!!state.filters) {
              state.filters[groupIndex].filters[itemIndex].active =
                activeFilterList.includes(aFilterItem.id);
            }
          });
      });
  },
  [funcVars.updateSelectedFilter](state: AppState, payload: FilterType) {
    const activeFilterList = state.activeFilters as FilterType[];
    let doAdd = true;

    if (!!state.activeFilters) {
      state.activeFilters.map((filter, index) => {
        if (filter.id === payload.id) {
          activeFilterList.splice(index, 1);
          state.activeFilters = activeFilterList.sort();
          doAdd = false;
        }
      });
    }
    if (doAdd) {
      activeFilterList.push(payload);
      state.activeFilters = activeFilterList.sort();
    }
  },
};
