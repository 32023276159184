import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "notification-list"
}

import NotificationBlock from "./NotificationBlock.vue";
import { StorageTypes } from "@/enums/StorageTypes";
import { StorageKeys } from "@/enums/StorageKeys";
import storageService from "@/services/StorageResource";
import store from "@/vuex/store";
import { computed } from "vue";
import { Capacitor } from '@capacitor/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationListBlock',
  setup(__props) {

const notifications = computed(() => {
  const notifications = store.getters["notifications"];
  const hasAcceptedCookies = storageService.getItem(
    StorageTypes.LOCAL,
    StorageKeys.HAS_ACCEPTED_COOKIES
  );

  if (!hasAcceptedCookies && Capacitor.getPlatform() !== 'ios') {
    store.getters["notifications"].push({
      message: "ui.texts.cookie_text",
      callback: acceptCookie,
    });
  }

  return notifications;
});
const acceptCookie = () => {
  storageService.setItem(
    StorageTypes.LOCAL,
    StorageKeys.HAS_ACCEPTED_COOKIES,
    true
  );
}

return (_ctx: any,_cache: any) => {
  return (!!notifications.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.value, (notification, index) => {
            return (_openBlock(), _createBlock(NotificationBlock, {
              response: notification,
              key: index
            }, null, 8, ["response"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})