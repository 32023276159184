interface Notification {
  id: string;
  type: string;
  message: string;
}
import store from '@/vuex/store';
// tslint:disable-next-line: class-name
class _NotificationService {
  public create(notification: Notification): void {
    this.getType(notification).then((type) => {
      store.getters['notifications'].push({ ...notification, ...type });

      if (type.timeout) {
        setTimeout(() => {
          this.destroy(notification.id);
        }, type.timeout);
      }
    });
  }

  public destroy(notificationId: string): void {
    const isLargeNumber = (element: any) => element.id === notificationId;
    const id: number = store.getters['notifications'].findIndex(isLargeNumber);
    store.getters['notifications'].splice(id, 1);
  }

  private getType(response: any): Promise<any> {
    return new Promise((resolve) => {
      if (response) {
        switch (response.type) {
          case 'error':
            resolve({ className: 'is-error', timeout: null });
            break;
          case 'success':
            resolve({ className: 'is-success', timeout: 8000 });
            break;
          case 'warning':
            resolve({ className: 'is-warning', timeout: 10000 });
            break;
          default:
            resolve({ className: 'is-info', timeout: 10000 });
        }
      }
      resolve(null);
    });
  }
}
const NotificationService = new _NotificationService();
export default NotificationService;
