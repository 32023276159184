import { GetterTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';

export const getters: GetterTree<AppState, RootState> = {
  reservations(state: AppState): any {
    return state.reservations;
  },

  consumerIdentifier(state: AppState): string {
    return state.consumerIdentifier;
  },
};
