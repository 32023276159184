import { MutationTree } from 'vuex';
import { AppState } from '../../vuex/types';
import funcVars from '../../vuex/vuexVars';
import { StorageTypes } from '@/enums/StorageTypes';
import { StorageKeys } from '@/enums/StorageKeys';
import storageService from '@/services/StorageResource';

export const mutations: MutationTree<AppState> = {
  [funcVars.updateActiveTab](state: AppState, payload: any) {
    state.activeTab = payload;
  },

  [funcVars.updateLoaderMessage](state: AppState, loaderMessage: string) {
    state.loaderMessage = loaderMessage;
  },

  [funcVars.updateTexts](state: AppState, texts: any) {
    state.texts = texts;
  },

  [funcVars.updateHasShownOnboarding](state: AppState, payload: any) {
    state.hasShownOnboarding = payload;
    storageService.setItem(
      StorageTypes.LOCAL,
      StorageKeys.SHOWN_ON_BOARDING_SCREEN,
      JSON.stringify(payload)
    );
  },
};
