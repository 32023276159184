import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import NotificationService from '../../services/NotificationService';
import { ref } from 'vue';
import { IonToast, IonButton } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationBlock',
  props: { response: Object as any },
  setup(__props) {

const props = __props;
const isOpen = ref(true);
const close = (): void => {
  NotificationService.destroy(props.response.id);
  isOpen.value = false;
  if (typeof props.response.callback === 'function') {
    props.response.callback();
  }
}

return (_ctx: any,_cache: any) => {
  return (isOpen.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "u-backdrop" }, null, -1)),
        _createVNode(_unref(IonToast), {
          mode: "ios",
          "is-open": isOpen.value,
          message: _ctx.$t(__props.response?.message),
          buttons: [{ text: _ctx.$t('ui.buttons.ok'), color: 'white' }],
          onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (close()))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonButton), {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (close()))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("ok")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["is-open", "message", "buttons"])
      ]))
    : _createCommentVNode("", true)
}
}

})