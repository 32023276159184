<template>
  <div id="notification-list" v-if="!!notifications">
    <div>
      <notification-block :response="notification" :key="index" v-for="(notification, index) in notifications" />
    </div>
  </div>
</template>

<script setup lang="ts">
import NotificationBlock from "./NotificationBlock.vue";
import { StorageTypes } from "@/enums/StorageTypes";
import { StorageKeys } from "@/enums/StorageKeys";
import storageService from "@/services/StorageResource";
import store from "@/vuex/store";
import { computed } from "vue";
import { Capacitor } from '@capacitor/core';

const notifications = computed(() => {
  const notifications = store.getters["notifications"];
  const hasAcceptedCookies = storageService.getItem(
    StorageTypes.LOCAL,
    StorageKeys.HAS_ACCEPTED_COOKIES
  );

  if (!hasAcceptedCookies && Capacitor.getPlatform() !== 'ios') {
    store.getters["notifications"].push({
      message: "ui.texts.cookie_text",
      callback: acceptCookie,
    });
  }

  return notifications;
});
const acceptCookie = () => {
  storageService.setItem(
    StorageTypes.LOCAL,
    StorageKeys.HAS_ACCEPTED_COOKIES,
    true
  );
}
</script>
