import { GetterTree } from 'vuex';
import { AppState, RootState, FilterGroup, FilterType } from '../../vuex/types';
import { AreaCampingType } from '@/enums/AreaCampingType';
import { TimeOfDay } from '@/enums/TimeOfDay';
export const getters: GetterTree<AppState, RootState> = {
  activeFilters(state: AppState): FilterType[] {
    return state.activeFilters || [];
  },
  filters(state: AppState): FilterGroup[] {
    return state.filters || [];
  },
  toDate(state: AppState): Date | undefined {
    return state.toDate || undefined;
  },
  areaCampingTypes(state: AppState): AreaCampingType[] {
    return state.areaCampingTypes;
  },
  propertyValues(state: AppState): any {
    return state.propertyValues;
  },
  fromDate(state: AppState): Date | undefined {
    return state.fromDate || undefined;
  },
  timeOfDay(state: AppState): TimeOfDay {
    return state.timeOfDay;
  },
  searchTerm(state: AppState): string | undefined {
    return state.searchTerm || undefined;
  },
};
