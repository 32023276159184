import { MutationTree } from 'vuex';
import { AppState } from '../../vuex/types';
import funcVars from '../../vuex/vuexVars';

export const mutations: MutationTree<AppState> = {
  [funcVars.updateReservations](state: AppState, payload: any) {
    state.reservations = payload;
  },
  [funcVars.updateConsumerIdentifier](
    state: AppState,
    consumerIdentifier: any
  ) {
    state.consumerIdentifier = consumerIdentifier;
  },
};
