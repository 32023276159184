import Constants from '@/utils/Constants';
import { StorageTypes } from '@/enums/StorageTypes';
import { StorageKeys } from '@/enums/StorageKeys';
import storageService from '@/services/StorageResource';
import { format } from 'date-fns';
import { sv, de, enGB } from 'date-fns/locale';
import ServicePlace from '@/models/ServicePlace';
import MarkerData from '@/models/MarkerData';
import { AreaCampingType } from '@/enums/AreaCampingType';
import i18n from '@/i18n/i18n';
import { ProductType } from '@/enums/ProductType';
import Product from '@/models/Product';
import AreaPrice from '@/models/AreaPrice';
import { compareAsc } from 'date-fns';

export default new (class Utils {
  public addTranslationsIfNotExists(elements) {
    if (!elements || elements.length === 0) {
      elements = [];
    }

    const svTranslations = elements.filter(
      (element) => element.language === 'sv'
    );
    const svTranslation = svTranslations[0];
    // Remove if to many translations
    if (svTranslations && svTranslations.length > 1) {
      elements = [svTranslation];
    }

    const enTranslation = elements.filter(
      (element) => element.language === 'en'
    )[0];

    const deTranslation = elements.filter(
      (element) => element.language === 'de'
    )[0];

    if (!svTranslation) {
      elements.push({
        language: 'sv',
      });
    }

    if (!enTranslation) {
      elements.push({
        language: 'en',
      });
    }

    if (!deTranslation) {
      elements.push({
        language: 'de',
      });
    }

    return elements;
  }

  public getTranslation(translationsArray, activeLanguage = 'sv') {
    const language = activeLanguage ?? i18n.global.locale;
    const translation = translationsArray.filter((item: any) => {
      return item.language === language;
    })[0];
    if (!translation) {
      const newTranslation = { language: language };

      translationsArray.push(newTranslation);

      return newTranslation;
    }

    return translation;
  }

  public changeLanguage(toLanguage) {
    localStorage.lang = toLanguage;
    window.location.href = window.location.href.replace(
      i18n.global.locale,
      toLanguage
    );
  }

  public trimLast(str: string, char: string): string {
    if (str[str.length - 1] === char) {
      str = str.substring(0, str.length - 1);
    }

    return str;
  }

  public capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  public dateToDateString(date: Date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = date.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    }

    return '';
  }

  public dateToDateTimeString(date: Date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (date) {
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return yyyy + '-' + mm + '-' + dd + ' ' + hours + ':' + minutes;
    }

    return '';
  }

  public dateToTimeString(date: Date) {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    if (date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return hours + ':' + minutes;
    }
  }

  public formatDayMonthString(dateString: Date): string {
    if (dateString) {
      try {
        return format(new Date(dateString), 'd MMM', {
          locale:
            i18n.global.locale === 'sv'
              ? sv
              : i18n.global.locale === 'de'
              ? de
              : enGB,
        });
      } catch {
        // Do nothing
      }
    }

    return '';
  }

  public formatDayMonthTimeString(dateString: Date) {
    if (dateString) {
      return format(new Date(dateString), 'dd MMM HH:mm', {
        locale:
          i18n.global.locale === 'sv'
            ? sv
            : i18n.global.locale === 'de'
            ? de
            : enGB,
      });
    }
  }

  public getDates(startDate: Date, stopDate: Date): Date[] {
    const dateArray: Array<Date> = [];
    const currentDate = new Date(startDate);
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  public dateEquals(date1: Date, date2: Date): boolean {
    return !(date1 > date2 || date1 < date2);
  }

  public newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public getLatestCancelReservationDate() {
    const latestCancelReservationDate = new Date();
    latestCancelReservationDate.setTime(
      latestCancelReservationDate.getTime() +
        Constants.HoursToCancelReservation * 60 * 60 * 1000
    );

    return latestCancelReservationDate;
  }

  public getLatestCreditReservationDate() {
    const latestCreditReservationDate = new Date();
    latestCreditReservationDate.setDate(
      latestCreditReservationDate.getDate() - 30
    );

    return latestCreditReservationDate;
  }

  public getLatestAddProductDate() {
    const latestAddProductDate = new Date();
    latestAddProductDate.setTime(
      latestAddProductDate.getTime() +
        Constants.HoursToCancelReservation * 60 * 60 * 1000
    );

    return latestAddProductDate;
  }

  public getImageSrc(
    url: string | undefined,
    width: number,
    height: number
  ): string | undefined {
    if (url?.indexOf('http') !== -1) {
      return url;
    }

    const imageFileUrl = this.replaceOldImageUrl(url);

    url = '/images/image/' + width + '/' + height + '/' + imageFileUrl;

    url =
      this.trimLast(
        storageService.getItem(StorageTypes.LOCAL, StorageKeys.API),
        '/'
      ) + url;

    return url;
  }

  public getLastSegment(url: string) {
    const segments = url.split('/');
    const lastSegment = segments.pop();

    return lastSegment;
  }

  public getFirstSegment(url: string) {
    const segments = url.split('/');
    const firstSegment = segments[0];

    return firstSegment;
  }

  public replaceOldImageUrl(imageUrl: string): string {
    const oldStringPath = '/images/image/2000/1600/';

    if (imageUrl.indexOf(oldStringPath) !== -1) {
      return imageUrl.replace(oldStringPath, '');
    }

    return imageUrl;
  }

  public parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public getHashValue(key: string) {
    const matches = location.hash.match(new RegExp(key + '=([^&%]*)'));
    return matches ? matches[1] : null;
  }

  public getQueryStringValue(key: string) {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(key);

    return value;
  }

  public setQueryStringValue(key: string, value: AreaCampingType) {
    if ('URLSearchParams' in window) {
      const params = new URLSearchParams(window.location.search);
      params.set(key, value.toString());
      const newRelativePathQuery =
        window.location.pathname + '?' + params.toString();
      history.pushState(null, '', newRelativePathQuery);
    }
  }

  public isAdminOrOwner(
    ownerEmail: string | undefined,
    userEmail: string | undefined
  ): boolean {
    return (
      userEmail != undefined &&
      (ownerEmail === userEmail || userEmail.indexOf('eremit.app') !== -1)
    );
  }

  public isAdmin(userEmail: string): boolean {
    return userEmail != null && userEmail.indexOf('eremit.app') !== -1;
  }

  public distinct(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  public getProductTypeIcon(productType: ProductType | undefined) {
    switch (productType) {
      case 0:
        return null;
      case ProductType.EremitBoat:
        return '<span class="mdi mdi-ferry"></span>';
      case ProductType.EremitHotTube:
        return '<span class="mdi mdi-bucket"></span>';
      case ProductType.EremitCrayfish:
        return '<img class="icon icon--white-circle" src="/images/icons/crayfish.png" />';
      case ProductType.EremitHunting:
        return '<img class="icon icon--white-circle" src="/images/icons/deer.png" />';
      default:
        return '<span class="mdi mdi-plus"></span>';
    }
  }

  public getAreaCampingTypeIcon(areaCampingType: AreaCampingType) {
    switch (areaCampingType) {
      case AreaCampingType.Boat:
        return '<img src="/images/icons/markers/marker-boat-white.svg" />';

      case AreaCampingType.Eremit:
        return '<img src="/images/icons/markers/marker-rv-white.svg" />';

      case AreaCampingType.EremitMicroCamp:
        return '<img src="/images/icons/markers/marker-micro-camp-white.svg" />';

      case AreaCampingType.Hunting:
        return '<img src="/images/icons/markers/marker-hunting.svg" />';

      case AreaCampingType.Hermitage:
        return '<img src="/images/icons/markers/marker-hermitage-white.svg" />';

      default:
        return '<span class="mdi mdi-map-marker-question"></span>';
    }
  }

  public mapServicePlaceMarker(
    servicePlace: ServicePlace,
    index: number,
    draggable: boolean
  ) {
    const servicePlaceTranslation = this.getTranslation(
      servicePlace.translations
    );

    const title =
      index +
      1 +
      ': ' +
      (servicePlaceTranslation ? servicePlaceTranslation.name : '');

    return new MarkerData(
      servicePlace.latitude,
      servicePlace.longitude,
      this.getServiceMarker(servicePlace.servicePlaceType, true),
      `<div class="container-fluid py-2"><h2>${
        servicePlaceTranslation ? servicePlaceTranslation.name : ''
      }</h2>
              ${
                servicePlace.servicePlaceImages &&
                servicePlace.servicePlaceImages.length > 0
                  ? '<div class="row"><div class="col-12 col-sm-4"><img src="' +
                    this.getImageSrc(
                      servicePlace.servicePlaceImages[0].image.url,
                      300,
                      200
                    ) +
                    '" class="map-image u-width-100" /></div><div class="col-12 col-sm-8">' +
                    (servicePlaceTranslation
                      ? servicePlaceTranslation.shortDescription
                      : '')
                  : '<div class="row"><div class="col-12">' +
                    (servicePlaceTranslation &&
                    servicePlaceTranslation.shortDescription
                      ? servicePlaceTranslation.shortDescription
                      : '')
              }
              <br>
              <br>
              ${
                servicePlaceTranslation &&
                servicePlaceTranslation.description &&
                servicePlaceTranslation.description.length > 10
                  ? '<span class="js-service-place-more-info-button me-3" data-service-place-index="' +
                    index +
                    '">' +
                    i18n.global.t(
                      'ui.reservation_details.service_place_more_info'
                    ) +
                    '</span>'
                  : ''
              }<a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${
        servicePlace.latitude + ',' + servicePlace.longitude
      }">${i18n.global.t('ui.buttons.open_google_map')}</a></div></div></div>
            `,
      title,
      title,
      26,
      26,
      draggable,
      2,
      false
    );
  }

  public getServiceMarker(type: number, active: boolean) {
    let marker = '';
    switch (type) {
      case 0:
        marker = 'marker-latrine-emptying';
        break;

      case 2:
        marker = 'marker-strawberry-place';
        break;

      default:
        break;
    }

    return (
      '/images/icons/markers/' + marker + (active ? '' : '-disabled') + '.svg'
    );
  }

  public getQueryFromObject(obj: any) {
    return Object.keys(obj)
      .map((key) => key + '=' + obj[key])
      .join('&');
  }

  public getAvailableDays = (
    mainProducts: Product[],
    areaPrices: AreaPrice[],
    fromDate: Date
  ): any => {
    // Arrays for available from dates and to dates
    const availableFromDaysArray: any = [];
    const availableToDaysArray: any = [];

    if (mainProducts && mainProducts[0]) {
      // Array for disabled days from backend (already booked days)
      let disabledFromDaysArray: any[] = [];
      let disabledToDaysArray: any[] = [];

      // Todo now we check that every place is available, if we introduce microcamping there will be possible to only check that the first place is available
      mainProducts.forEach((product) => {
        product.disabledFromDays?.forEach((dateInterval: any) => {
          disabledFromDaysArray = disabledFromDaysArray.concat(
            this.getDates(
              new Date(dateInterval.start),
              new Date(dateInterval.end)
            )
          );
        });

        product.disabledToDays?.forEach((dateInterval: any) => {
          disabledToDaysArray = disabledToDaysArray.concat(
            this.getDates(
              new Date(dateInterval.start),
              new Date(dateInterval.end)
            )
          );
        });
      });
      disabledFromDaysArray = disabledFromDaysArray.sort((a, b) => a - b);
      disabledToDaysArray = disabledToDaysArray.sort((a, b) => a - b);

      // Get available days from pricelists
      let daysInPriceLists: any = [];
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      areaPrices.forEach((areaPrice: any) => {
        // Set year of date to this year
        const todaysYear = today.getFullYear();
        const from = new Date(areaPrice.price.from);
        from.setFullYear(todaysYear);
        const to = new Date(areaPrice.price.to);
        to.setFullYear(todaysYear);
        daysInPriceLists = daysInPriceLists.concat(
          this.getDates(new Date(from), new Date(to))
        );
      });

      areaPrices.forEach((areaPrice: any) => {
        // Set year of date to this year
        const todaysYear = today.getFullYear() + 1;
        const from = new Date(areaPrice.price.from);
        from.setFullYear(todaysYear);
        const to = new Date(areaPrice.price.to);
        to.setFullYear(todaysYear);
        daysInPriceLists = daysInPriceLists.concat(
          this.getDates(new Date(from), new Date(to))
        );
      });

      // Order dates
      daysInPriceLists.sort(compareAsc);
      daysInPriceLists = daysInPriceLists.filter(this.distinct);

      daysInPriceLists.forEach((date: Date) => {
        const startDate = new Date(date);
        const endDate = new Date(date);

        // Check if date is available then add
        if (
          disabledFromDaysArray.filter((disabledFromDate: Date) => {
            return (
              this.dateToDateString(disabledFromDate) ===
              this.dateToDateString(date)
            );
          }).length === 0 &&
          date >= today
        ) {
          availableFromDaysArray.push({
            start: startDate,
            end: endDate,
          });
        }
        if (
          disabledToDaysArray.filter((disabledToDate: Date) => {
            return (
              this.dateToDateString(disabledToDate) ===
              this.dateToDateString(date)
            );
          }).length === 0 &&
          endDate >= fromDate
        ) {
          // Check if first available date or previous date was added else do not add
          let dateToCompare = new Date();

          if (availableToDaysArray.length !== 0) {
            dateToCompare = new Date(date);

            dateToCompare.setDate(dateToCompare.getDate() - 1);
          }

          if (
            availableToDaysArray.length === 0 ||
            this.dateToDateString(
              availableToDaysArray[availableToDaysArray.length - 1].start
            ) === this.dateToDateString((dateToCompare as Date) ?? new Date())
          ) {
            availableToDaysArray.push({
              start: startDate,
              end: endDate,
            });
          }
        }
      });
    }

    return {
      availableFromDaysArray,
      availableToDaysArray,
    };
  };
})();
