import funcVars from '../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState } from '../../vuex/types';
import store from '@/vuex/store';
import reservationsResource from '@/services/ReservationResource';

export const actions: ActionTree<AppState, RootState> = {
  async getReservations({ commit }) {
    return await reservationsResource
      .getReservations()
      .then((response: any) => {
        commit(funcVars.updateReservations, response);
        return response;
      });
  },
  async createReservation({ commit }, payload: any) {
    return await reservationsResource
      .createReservation(payload)
      .then((response: any) => {
        store.dispatch('getAreas');

        return response;
      });
  },
  async updateReservation({ commit }, payload: any) {
    return await reservationsResource
      .updateReservation(payload)
      .then((response: any) => {
        return response;
      });
  },
  async updateConsumerIdentifier({ commit }, consumerIdentifier: any) {
    commit(funcVars.updateConsumerIdentifier, consumerIdentifier);
  },
  async creditReservation({ commit }, payload: any) {
    return await reservationsResource
      .creditReservation(payload)
      .then((response: any) => {
        // Trigger get reservations
        store.dispatch('getReservations');
        store.dispatch('getAreas');

        return response;
      });
  },
  async cancelReservation({ commit }, payload: any) {
    return await reservationsResource
      .cancelReservation(payload)
      .then((response: any) => {
        // Trigger get reservations
        store.dispatch('getReservations');
        store.dispatch('getAreas');

        return response;
      });
  },
};
