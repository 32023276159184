import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import { IonLoading } from "@ionic/vue";
import store from "@/vuex/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderComponent',
  setup(__props) {

const loaderMessage = computed(() => {
  return store.getters["loaderMessage"];
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonLoading), {
    "is-open": loaderMessage.value != null && loaderMessage.value != undefined,
    message: loaderMessage.value
  }, null, 8, ["is-open", "message"]))
}
}

})