// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
import { createApp } from 'vue';
import App from '@/App.vue';
import storageResource from './services/StorageResource';
import { StorageTypes } from './enums/StorageTypes';
import { StorageKeys } from './enums/StorageKeys';
import { Capacitor } from '@capacitor/core';
import i18n from '@/i18n/i18n';
import store from './vuex/store';
import routerResource from '@/services/RouterResource';
import VCalendar from 'v-calendar';
import VueSocialSharing from 'vue-social-sharing';
import { createMetaManager, defaultConfig, deepestResolver } from 'vue-meta';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import authService from './services/AuthService';
import { IonicVue } from '@ionic/vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueGtag from 'vue-gtag';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';

import './assets/main.scss';
import trackingService from './services/TrackingService';
if (typeof navigator.serviceWorker !== 'undefined') {
  navigator.serviceWorker.register('service-worker.js');
}

import polyfills from './polyfills';
polyfills.init();

import axiosMiddleWare from './utils/AxiosMiddleware';
axiosMiddleWare.init();

import loadGoogleMapsApi from 'load-google-maps-api-2';

loadGoogleMapsApi({
  key: process.env.VUE_APP_GOOGLEMAPS_KEY,
}).then((googleMaps: any) => {
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.API,
    process.env.VUE_APP_ENVIRONMENT === 'development' ||
      Capacitor.platform === 'ios'
      ? process.env.VUE_APP_API_URL
      : window.location.origin
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_CLIENT_URI,
    Capacitor.platform === 'ios' ? 'app.eremit:/' : window.location.origin
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_CLIENT_ID,
    process.env.VUE_APP_B2C_CLIENT_ID
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_NATIVE_CLIENT_ID,
    process.env.VUE_APP_B2C_NATIVE_CLIENT_ID
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_AUTHORITY,
    process.env.VUE_APP_B2C_B2C_AUTHORITY
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_PASSWORDRESETAUTHORITY,
    process.env.VUE_APP_B2C_PASSWORDRESETAUTHORITY
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_SCOPE,
    process.env.VUE_APP_B2C_SCOPE
  );
  storageResource.setItem(
    StorageTypes.LOCAL,
    StorageKeys.B2C_EDIT_PROFILE_URL,
    process.env.VUE_APP_B2C_EDIT_PROFILE_URL
  );

  authService.getValuesFromStorage();

  // Get oruter
  const router = routerResource.getRouter();

  // Add event for deep links
  CapacitorApp.addListener(
    'appUrlOpen',
    function (event: URLOpenListenerEvent) {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      // Split for eremit.app
      let path = event.url.split('.app').pop() ?? '';

      // Split for azurewebsite.net
      path = path.split('.net').pop() ?? '';

      // Split for native app
      path = path.split('#')[0];
      path = path.split('?')[0];
      path = path.split('app.eremit://localhost').pop() ?? '';

      // path = path.split('localhost').pop() ?? '';

      // Query
      const queryString =
        event.url.indexOf('?') !== -1 ? event.url.split('?').pop() : '';

      const query = Object.fromEntries(new URLSearchParams(queryString));

      // Get hash
      const hash =
        event.url.indexOf('#') !== -1 ? `#${event.url.split('#').pop()}` : '';

      query.redirectUrl = window.location.href;

      // We only push to the route if there is a slug present
      if (path) {
        router.push({
          path: path,
          query: query,
          hash: hash,
        });
      }
    }
  );

  const app = createApp(App)
    .use(IonicVue, {
      innerHTMLTemplatesEnabled: true,
    })
    .use(router)
    .use(CkeditorPlugin)
    .use(VCalendar)
    .use(VueSocialSharing)
    .use(store)
    .use(i18n)
    .use(VueEasyLightbox)
    .use(createMetaManager(false, defaultConfig, deepestResolver))
    .use(VueGtag, {
      config: { id: 'G-7GNS1LWE4V' },
      // router: router,
      enabled: false,
    });

  app.provide('gtag', app.config.globalProperties.$gtag);
  store.dispatch('getCurrentUser');
  trackingService.requestPermission().then(() => {
    trackingService.isAuthorized().then((isAuth) => {
      if (isAuth) {
        // Google analytics tracking
        app.config.globalProperties.$gtag.optIn();

        // Facebook pixel tracking
        fbq('consent', 'grant');
      } else {
        app.config.globalProperties.$gtag.optOut();
      }
    });
  });

  window.$googleMaps = googleMaps;

  app.directive('overflow-visible', {
    beforeMount: (el) => {
      setTimeout(() => {
        const toolbar: HTMLElement = el;
        const container = toolbar?.shadowRoot?.querySelector(
          '.toolbar-container'
        ) as HTMLElement;

        if (container) {
          container.style.overflow = 'visible';
          container.style.contain = 'none';
        }
      }, 500);
    },
  });

  router.isReady().then(() => {
    app.mount('#app');
  });
});
