import storageResource from './StorageResource';
import { StorageTypes } from '../enums/StorageTypes';
import { StorageKeys } from '../enums/StorageKeys';
import store from '@/vuex/store';
import i18n from '@/i18n/i18n';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

export let AccessToken: string | null = storageResource.getItem(
  StorageTypes.LOCAL,
  StorageKeys.B2C_ACCESS_TOKEN
);
export let IdToken: string | null = '';
export let ProfileOAuthConfig: any = null;
export let SignOutOAuthConfig: any = null;
export let SignInOAuthConfig: any = null;

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};

export class AuthorizeService {
  public async editProfile(reload: boolean) {
    store.dispatch('setLoaderMessage', i18n.global.t('ui.labels.loading'));

    await OAuth2Client.authenticate(ProfileOAuthConfig)
      .then((response: any) => {
        const accessToken = response.access_token_response?.access_token;
        this.setAccessToken(accessToken);

        const idToken = response.access_token_response?.id_token;

        this.setIdToken(idToken);

        // Perhaps check if should reload first
        if (reload) {
          location.reload();
        }

        store.dispatch('setLoaderMessage', null);
      })
      .catch((error: any) => {
        console.debug(error);

        // Try to sign in
        this.signIn(true);
        store.dispatch('setLoaderMessage', null);
      });
  }

  public async signIn(reload: boolean) {
    store.dispatch('setLoaderMessage', i18n.global.t('ui.labels.loading'));

    await OAuth2Client.authenticate(SignInOAuthConfig).catch((error: any) => {
      console.debug(error);
      store.dispatch('setLoaderMessage', null);
    });
  }

  public async signOut() {
    const clientId =
      Capacitor.getPlatform() === 'ios'
        ? storageResource.getItem(
            StorageTypes.LOCAL,
            StorageKeys.B2C_NATIVE_CLIENT_ID
          )
        : storageResource.getItem(
            StorageTypes.LOCAL,
            StorageKeys.B2C_CLIENT_ID
          );
    const url = `${storageResource.getItem(
      StorageTypes.LOCAL,
      StorageKeys.B2C_AUTHORITY
    )}logout?client_id=${clientId}`;

    //response_type=code&redirect_uri=app.eremit://authentication/logout-callback&scope=openid%20offline_access%20https://eremitnu.onmicrosoft.com/api/demo.read' });

    await Browser.open({ url }).finally(() =>
      setTimeout(() => {
        Browser.close();
      }, 1500)
    );

    Browser.close();

    await OAuth2Client.logout(SignInOAuthConfig)
      .then(async () => {
        this.setAccessToken(null);
        this.setIdToken(null);

        window.location.href = '/';
      })
      .catch((reason: any) => {
        console.error('OAuth logout failed', reason);
      });
  }

  public setAccessToken(value: string | null) {
    IdToken = value;
    storageResource.setItem(
      StorageTypes.LOCAL,
      StorageKeys.B2C_ACCESS_TOKEN,
      value
    );
  }

  public setIdToken(value: string | null) {
    IdToken = value;
    storageResource.setItem(
      StorageTypes.LOCAL,
      StorageKeys.B2C_ID_TOKEN,
      value
    );
  }

  public getValuesFromStorage() {
    IdToken = storageResource.getItem(
      StorageTypes.LOCAL,
      StorageKeys.B2C_ID_TOKEN
    );

    AccessToken = storageResource.getItem(
      StorageTypes.LOCAL,
      StorageKeys.B2C_ACCESS_TOKEN
    );

    const commonOAuthConfig = {
      appId: storageResource.getItem(
        StorageTypes.LOCAL,
        StorageKeys.B2C_CLIENT_ID
      ),
    };

    ProfileOAuthConfig = {
      ...commonOAuthConfig,
      authorizationBaseUrl:
        storageResource.getItem(
          StorageTypes.LOCAL,
          StorageKeys.B2C_EDIT_PROFILE_URL
        ) + 'authorize',
      accessTokenEndpoint: '',
      scope: storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_SCOPE),
      responseType: 'id_token token',
      redirectUrl: `${window.location.origin}/${i18n.global.locale}/authentication/profile`,
      ios: {
        pkceEnabled: true,
        responseType: 'code id_token token',
        appId: storageResource.getItem(
          StorageTypes.LOCAL,
          StorageKeys.B2C_NATIVE_CLIENT_ID
        ),
        redirectUrl: `app.eremit://localhost/${i18n.global.locale}/authentication/profile`,
        accessTokenEndpoint:
          storageResource.getItem(
            StorageTypes.LOCAL,
            StorageKeys.B2C_AUTHORITY
          ) + 'token',
      },
      web: {
        windowTarget: '_self',
      },
    };

    SignOutOAuthConfig = {
      ...commonOAuthConfig,

      authorizationBaseUrl:
        storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_AUTHORITY) +
        'logout',
      scope:
        'openid offline_access https://eremitnu.onmicrosoft.com/api/demo.read',
      responseType: 'id_token',
      redirectUrl: `${window.location.origin}/${i18n.global.locale}/authentication/logout-callback`,
      logoutUrl:
        storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_AUTHORITY) +
        'logout',
      accessTokenEndpoint: null,
      resourceUrl: null,
      ios: {
        pkceEnabled: true,
        responseType: 'code id_token token',
        appId: storageResource.getItem(
          StorageTypes.LOCAL,
          StorageKeys.B2C_NATIVE_CLIENT_ID
        ),
        redirectUrl: `app.eremit://localhost/${i18n.global.locale}/authentication/logout-callback`,
      },
      web: {},
    };

    SignInOAuthConfig = {
      ...commonOAuthConfig,
      authorizationBaseUrl:
        storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_AUTHORITY) +
        'authorize',
      accessTokenEndpoint: '',
      scope: storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_SCOPE),
      responseType: 'id_token token',
      redirectUrl: `${window.location.origin}/${i18n.global.locale}/authentication/login-callback`,
      logoutUrl:
        storageResource.getItem(StorageTypes.LOCAL, StorageKeys.B2C_AUTHORITY) +
        'logout',
      ios: {
        pkceEnabled: true,
        responseType: 'code id_token token',
        appId: storageResource.getItem(
          StorageTypes.LOCAL,
          StorageKeys.B2C_NATIVE_CLIENT_ID
        ),
        redirectUrl: `app.eremit://localhost/${i18n.global.locale}/authentication/login-callback`,
        accessTokenEndpoint:
          storageResource.getItem(
            StorageTypes.LOCAL,
            StorageKeys.B2C_AUTHORITY
          ) + 'token',
      },
      web: {
        windowTarget: '_self',
      },
    };
  }
}

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
};

export interface ReturnStatus {
  status: string;
  message?: string;
  state?: { returnUrl: string };
  redirectUrl?: string;
}

const authService = new AuthorizeService();

export default authService;
