import funcVars from './../../vuex/vuexVars';
import { ActionTree } from 'vuex';
import { AppState, RootState, FilterType } from '../../vuex/types';
import store from '@/vuex/store';
import { AreaCampingType } from '@/enums/AreaCampingType';
import { TimeOfDay } from '@/enums/TimeOfDay';

export const actions: ActionTree<AppState, RootState> = {
  async updateToDate({ commit }, payload: Date) {
    if (payload) {
      commit(funcVars.updateToDate, payload);
    }
  },
  async updateFromDate({ commit }, payload: Date) {
    if (payload) {
      commit(funcVars.updateFromDate, payload);
    }
  },
  async updateTimeOfDay({ commit }, payload: TimeOfDay) {
    if (payload) {
      commit(funcVars.updateTimeOfDay, payload);
    }
  },
  async updateAreaCampingTypes({ commit }, payload: AreaCampingType[]) {
    if (payload) {
      commit(funcVars.updateAreaCampingTypes, payload);
    }
  },
  async updatePropertyValues({ commit }, payload: any[]) {
    if (payload) {
      commit(funcVars.updatePropertyValues, payload);
    }
  },
  async setSearchTerm({ commit }, searchTerm: string) {
    commit(funcVars.updateSearchTerm, searchTerm);
  },

  async updateFilter({ commit }, payload: FilterType) {
    if (payload === null) {
      store.dispatch('applyFilterToProductList');
    } else {
      store.dispatch('setFilter', payload).then(() => {
        // const filterIds =
        //   (!!state.activeFilters &&
        //     state.activeFilters.map((filter) => {
        //       return filter.id;
        //     })) ||
        //   [];
        // filterService.saveFilters(filterIds.join(','), state.sort || 0);
        store.dispatch('applyFilterToProductList');
      });
    }
  },
  clearActiveFilters({ commit }) {
    commit(funcVars.clearActiveFilters);
    commit(funcVars.updateShadowFilters);
    store.dispatch('getProducts');
  },
  initActiveFilters({ commit }) {
    commit(funcVars.updateShadowFilters);
  },
  fetchFilters({ commit }) {
    commit(funcVars.updateShadowFilters);
  },
  async setFilter({ commit }, payload: FilterType) {
    commit(funcVars.updateSelectedFilter, payload);
    commit(funcVars.updateShadowFilters);
  },
};
