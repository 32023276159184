export enum ProductType {
  OwnProduct = 0,
  EremitBoat = 2,
  EremitHotTube = 4,
  EremitCrayfish = 10,
  EremitPlace = 20,
  EremitHunting = 25,
  EremitHermitage = 30,
  EremitBoatOnly = 35,
  Fee = 40,
}
