import 'capacitor-plugin-app-tracking-transparency'; // only if you want web support

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

export default new (class TrackingService {
  public async isAuthorized() {
    const response = await AppTrackingTransparency.getStatus();
    return response.status == 'authorized';
  }

  public async requestPermission(): Promise<typeof AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();
    return response;
  }
})();
